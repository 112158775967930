import React from 'react';

import Header from '../../elements/Header';
import Footer from '../../elements/Footer';

// styles
import GlobalStyles from '../../../styles/global';
import * as s from './styles';
import { useInView } from 'react-intersection-observer';


const PageLayout = ({children}) => {


  return (
    
    <s.StyledLayout>

      <noscript>
        {`
          <iframe src=https://www.googletagmanager.com/ns.html?id=GTM-NQFJDSTL height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
      </noscript>

      <GlobalStyles />

      <s.PageMain>
        {children}
      </s.PageMain>

      <Footer />

    </s.StyledLayout>
  );
}


export default PageLayout;
