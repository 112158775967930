import { css } from 'styled-components';

export const breakpoints = {
  sm: 768,
  md: 1024,
  lg: 1140,
  nav: 725,
  dosingImgs: 600,
  modalSm: 420,
  modalMd: 660,
  circleGraphLg: 900,
};


export const respondTo = Object.keys(breakpoints).reduce(
  (acc, label) => {
    acc[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}px) {
        ${css(...args)};
      }
    `;
    return acc;
  },
  {},
);

// Example Usage:

// export const ExampleComponent = styled.div`
//   background-color: lime;
//
//   ${respondTo.sm`
//     background-color: aqua;
// `  }
// `;