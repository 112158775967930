import logo from '../assets/images/Could_it_be_VHL_Logo.svg';
const headerCopy = {
  logo: logo,
  logoAlt: 'Could it be VHL',
  navItems: [   
    {
      label: 'Neoadjuvant therapy+surgery together',
      url: 'neo',
    },
    {
      label: 'What is the role of the multidisciplinary team in MIBC?',
      url: 'mibc',
    },
    {
      label: 'Why neoadjuvant therapy?',
      url: 'why',
    },
    {
      label: 'Considerations for surgery and neoadjuvant therapy',
      url: 'con',
    },
  ],
  mobileFooterLinks: [
    {
      label: 'Privacy policy',
      url: 'https://www.msdprivacy.com/us/en/index.html',
    },
    {
      label: 'Terms of use',
      url: 'https://www.merck.com/policy/terms-of-use/home.html',
    },
    {
      label: 'Cookie Preferences',
      type:'tertiary',
      href:"javascript:void(0)",
      className:"styles__Button-dwPtCw kANAfb styles__LegalLink-cunORN lmEMUp footer__horizontal-list-link ot-sdk-show-settings ot-sdk-show-settings",
      dataDesignCategory:'top_nav_vd',
      id: 'ot-sdk-btn',
    },
    {
      label: 'Accessibility',
      url: 'https://www.msdaccessibility.com',
    }
  ]
};


export default headerCopy;
