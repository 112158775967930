import React from 'react';
import * as s from './styles';
import  NonBreaking  from '../../../styles/shared/nonbreaking';
import logoAccessibility from '../../../assets/images/logo-accessibility.svg';
import footerCopy from '../../../copy/footer';

const Footer = () => {
  return (
    <s.WeliregFooter>
        <s.FooterWrapper>
        <s.FooterTop>

          <strong>References:&nbsp;1.</strong>&nbsp;Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup style={{color: '#444444'}}>®</sup>) for Bladder Cancer V.3.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed May 26, 2023. To view the most recent and complete version of the guidelines, go online to NCCN.org.
          <strong>&ensp;2.</strong>&nbsp;Advanced Bladder Cancer (ABC) Meta-analysis Collaboration. Neoadjuvant chemotherapy in invasive bladder cancer: update of a systematic review and meta-analysis of individual patient data. <i>Eur Urol</i>. 2005;48(2):202–206. doi:10.1016/j.eururo.2005.04.006
          <strong>&ensp;3.</strong>&nbsp;Grossman HB, Natale RB, Tangen CM, et al. Neoadjuvant chemotherapy plus cystectomy compared with cystectomy alone for locally advanced bladder cancer. <i>N Engl J Med.</i> 2003; 349(9):859–866. doi:10.1056/NEJMoa022148
          <strong>&ensp;4.</strong>&nbsp;National Cancer Institute. Dictionary of Cancer Terms. Neoadjuvant therapy.&nbsp;<NonBreaking>https://</NonBreaking><span>www.cancer.gov/publications/dictionaries/cancer-terms/def/neoadjuvant-therapy.</span> Accessed June 28, 2023.
          <strong>&ensp;5.</strong>&nbsp;Mayo Clinic. Adjuvant therapy: treatment to keep cancer from returning.&nbsp;<NonBreaking>https://</NonBreaking><span>www.mayoclinic.org/diseases-conditions/cancer/in-depth/adjuvant-therapy/art-20046687.</span> Updated May 5, 2022. Accessed July 5, 2023.
          <strong>&ensp;6.</strong>&nbsp;Cleveland Clinic. Metastasis (metastatic cancer).&nbsp;<NonBreaking>https://</NonBreaking><span>my.clevelandclinic.org/health/diseases/22213-metastasis-metastatic-cancer.</span> Published June 22, 2022. Accessed May 10, 2023.
          <strong>&ensp;7.</strong>&nbsp;American Cancer Society. Bladder cancer early detection, diagnosis, and staging.&nbsp;<NonBreaking>https://</NonBreaking><span>www.cancer.org/cancer/types/bladder-cancer/detection-diagnosis-staging/staging.html.</span> Updated January 30, 2019. Accessed August 9, 2023.
          <strong>&ensp;8.</strong>&nbsp;Chang SS, Bochner BH, Chou R, et al. Treatment of non-metastatic muscle-invasive bladder cancer: AUA/ASCO/ASTRO/SUO guideline. <i>J Urol</i>. 2017;198(3):552–559. doi:10.1016/j.juro.2017.04.086
          <strong>&ensp;9.</strong>&nbsp;Dason S, Cha EK, Falavolti C, et al. Late recurrences following radical cystectomy have distinct prognostic and management considerations. <i>J Urol</i>. 2020;204(3):460–465. doi:10.1097/JU0000000000001028
          <strong>&ensp;10.</strong>&nbsp;Martini A, Jia R, Ferket BS, et al. Tumor downstaging as an intermediate endpoint to assess the activity of neoadjuvant systemic therapy in patients with muscle-invasive bladder cancer. <i>Cancer</i>. 2019;125(18):3155–3163. doi:10.1002/cncr.32169
          <strong>&ensp;11.</strong>&nbsp;Travis WD, Dacic S, Wistuba I, et al. IASLC multidisciplinary recommendations for pathologic assessment of lung cancer resection specimens after neoadjuvant therapy. <i>J Thorac Oncol</i>. 2020;15(5):709–740. doi:10.1016/j.jtho.2020.01.005 
          <strong>&ensp;12.</strong>&nbsp;US Food & Drug Administration. Clinical trial endpoints for the approval of cancer drugs and biologics: guidance for industry.&nbsp;<NonBreaking>https://</NonBreaking><span>www.fda.gov/regulatory-information/search-fda-guidance-documents/clinical-trial-endpoints-approval-cancer-drugs-and-biologics.</span> Updated May 7, 2020. Accessed February 2, 2023.
          <strong>&ensp;13.</strong>&nbsp;Kilickap S, Demirci U, Karadurmus N, et al. Endpoints in oncology clinical trials. <i>J BUON</i>. 2018;23(7):1–6.
          <strong>&ensp;14.</strong>&nbsp;Delgado A, Guddati AK. Clinical endpoints in oncology - a primer. <i>Am J Cancer Res</i>. 2021;11(4):1121–1131.
          <strong>&ensp;15.</strong>&nbsp;National Cancer Institute. Dictionary of Cancer Terms. Pathologic complete response.&nbsp;<NonBreaking>https://</NonBreaking><span>www.cancer.gov/search/results?swKeyword=pathologic+complete+response.</span> Accessed August 22, 2023.
          <strong>&ensp;16.</strong>&nbsp;Galsky MD, Hahn NM, Rosenberg J, et al. Treatment of patients with metastatic urothelial cancer “unfit” for cisplatin-based chemotherapy. <i>J Clin Oncol</i>. 2011;29(17):2432–2438. doi:10.1200/JCO.2011.34.8433 

        </s.FooterTop>
          <s.FooterBottom>
          <s.CopyrightRow>
              <p>
                This site is intended for residents of the United States, its territories, and Puerto Rico. Copyright © 2023 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.
              </p>
              <br/>
              <p>US-MIB-00002 11/23</p>
            </s.CopyrightRow>    
            <s.BottomRightFlex>
            <s.LegalLinkRow>
                {footerCopy.legalLinks.map((legalLink, i) => (
                  <span>
                    <a
                      atype={legalLink.type}
                      key={i}
                      target={legalLink.target}
                      id={legalLink.id}
                      data-design-category={legalLink.dataDesignCategory}
                      href={legalLink.url}
                      className={legalLink.className}
                      >{legalLink.label}
                       {/* Conditionally render the image if it exists */}
                        {legalLink.image && (
                          <img
                            src={legalLink.image}
                            alt={``}
                            style={{ marginLeft: '8px'}} // Adjust styles as needed
                          />
                        )}
                    </a>
                  </span>
                ))}
            </s.LegalLinkRow>
            <s.PrivacyLinks>
              <div class="essential-accessibility-logo">
                <a 
                  href="https://www.essentialaccessibility.com/merck/?utm_source=merck-homepage&     utm_medium=icon-large&utm_term=eachannel-page&utm_content=header&utm_campaign=merck" target="_blank" title="" data-design-category="">
                  <img src={logoAccessibility} alt="Level Access Logo" width="86" height="33" />
                </a>
              </div>
              <s.MerckLogo className="merck-logo" src={footerCopy.merckLogo} alt="Merck Logo"/>
            </s.PrivacyLinks>
          </s.BottomRightFlex>
        </s.FooterBottom>
      </s.FooterWrapper>
    </s.WeliregFooter>
  );
}


export default Footer;
