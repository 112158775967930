import nsan from '../../assets/fonts/NotoSans-Regular.ttf';
import nsani from '../../assets/fonts/NotoSans-Italic.ttf';

import nsanl from '../../assets/fonts/NotoSans-Light.ttf';
import nsanli from '../../assets/fonts/NotoSans-LightItalic.ttf';

import nsanel from '../../assets/fonts/NotoSans-ExtraLight.ttf';
import nsaneli from '../../assets/fonts/NotoSans-ExtraLightItalic.ttf';

import nsanm from '../../assets/fonts/NotoSans-Medium.ttf';
import nsanmi from '../../assets/fonts/NotoSans-MediumItalic.ttf';

import nsanbo from '../../assets/fonts/NotoSans-Bold.ttf';
import nsanboi from '../../assets/fonts/NotoSans-BoldItalic.ttf';

import nsaneb from '../../assets/fonts/NotoSans-ExtraBold.ttf';
import nsanebi from '../../assets/fonts/NotoSans-ExtraBoldItalic.ttf';

import nsanb from '../../assets/fonts/NotoSans-Black.ttf';
import nsanbi from '../../assets/fonts/NotoSans-BlackItalic.ttf';

import nsant from '../../assets/fonts/NotoSans-Thin.ttf';
import nsanti from '../../assets/fonts/NotoSans-ThinItalic.ttf';

import nsansb from '../../assets/fonts/NotoSans-SemiBold.ttf';
import nsansbi from '../../assets/fonts/NotoSans-SemiBoldItalic.ttf';

import nser from '../../assets/fonts/NotoSerif-Regular.ttf';
import nseri from '../../assets/fonts/NotoSerif-Italic.ttf';

import nserb from '../../assets/fonts/NotoSerif-Bold.ttf';
import nserbi from '../../assets/fonts/NotoSerif-BoldItalic.ttf';



const fonts = `
    @import url('https://fonts.googleapis.com/css?family=PT+Serif|PT+Serif:bold');

    @font-face {
        font-family: 'arial';
        src: local('Arial');
        font-weight: normal;
    }

    @font-face {
        font-family: 'arial-bold';
        src: local('Arial-BoldMT'), local('Arial Bold'), local('arialbd');
        font-weight: bold;
    }

    @mixin arial() {
        font-family: "arial", Helvetica, sans-serif;
    }

    @mixin arialBold() {
        font-family: "arial-bold", "arial", Helvetica, sans-serif;
        font-weight: 600;
    }

    @mixin serifFontFamily() {
        font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;
    }

    @mixin serifFontFamilyBold() {
        font-family: 'PT Serif Bold', Georgia, 'Times New Roman', Times, serif;
        font-weight: 600;
    }

    @mixin georgia() {
        font-family: Georgia, 'Times New Roman', Times, serif;
    }

  `;

export default fonts;