import React, { useState } from 'react';
import * as s from './styles';
import headerCopy from '../../../copy/header';
import Button from '../Button';
import talkToDoctorCopy from '../../../copy/talkToDoctorShort';
import HeaderModal from '../HeaderModal'
import InfoI from './InfoI';
import scalpelImg from '../../../assets/images/hcp-neo-scalpel--desktop.png';

const Header = ({ NeoInView, WhyInView, MIBCInView, ConsiderationsInView}) => {
  
  const [navOpen, setNavOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [flipI, setFlipI] = useState(false);
  const [shadow, setShadow] = useState(false)

  const onOverlayClick = (e) => {
    e.stopPropagation();
    setNavOpen(false);
    document.body.classList.remove('no-scroll');
    handleNavClick(e.target.getAttribute("data-nav-item"));
  };

  const handleClick = () => {
    setShowModal(!showModal);
    setFlipI(!flipI);
    setShadow(!shadow);
  }


  const handleNavClick = (id) => {
    document.getElementById(id).scrollIntoView({
  behavior: 'smooth',
  block: 'start',
  inline: "nearest"
});
  }
  return (
    <s.WeliregHeader>
      <s.SecondaryHeader>This site is intended for US health care professionals only.</s.SecondaryHeader>
      <s.MobileWrap>
        <s.HamburgerButton
            onClick={() => {
              setNavOpen(!navOpen);
              document.body.classList.toggle('no-scroll');
            }}
            className={navOpen ? 'open' : ''}
            data-design-category="button_vd"
            aria-label={navOpen ? 'Close Mobile Nav' : 'Open Mobile Nav'}
          >
        </s.HamburgerButton>
      </s.MobileWrap>
      <s.TopHeader 
        // onClick={handleClick}
        addShadow={shadow}
      >
        <div className='text'>In stage ll and lllA muscle-invasive bladder cancer (MIBC)</div>
      {/* {
        showModal && <HeaderModal />
      } */}
      </s.TopHeader>
      <s.LeftHeader>
        <ul>
          <s.LeftHeaderNavLink onClick={()=>{handleNavClick('neo')}} active={NeoInView.inView}><a>Neoadjuvant therapy +<br/> surgery together</a></s.LeftHeaderNavLink>
          <s.LeftHeaderNavLink onClick={()=>{handleNavClick('mibc')}} active={WhyInView.inView}><a>What is the role of the multidisciplinary team in MIBC?</a></s.LeftHeaderNavLink>
          <s.LeftHeaderNavLink onClick={()=>{handleNavClick('why')}} active={MIBCInView.inView}><a>Why neoadjuvant therapy?</a></s.LeftHeaderNavLink>
          <s.LeftHeaderNavLink onClick={()=>{handleNavClick('con')}} active={ConsiderationsInView.inView}><a>Considerations for surgery  <br/> and neoadjuvant therapy</a></s.LeftHeaderNavLink>
        </ul>
       
      </s.LeftHeader>
      <s.HeaderWrapper>


        <s.MobileNavOverlay
            isActive={navOpen}
            onClick={onOverlayClick}
          />

          <s.NavTray className={navOpen ? 'open' : 'hidden'}>

            <s.MobileHeaderWrap>
              <s.HamburgerButton
                  onClick={() => {
                    setNavOpen(!navOpen);
                    document.body.classList.toggle('no-scroll');
                  }}
                  className={navOpen ? 'open' : ''}
                  data-design-category="button_vd"
                
                >
              </s.HamburgerButton>
              <s.MobNavImgWrap>
                <img src={scalpelImg} alt="" />
              </s.MobNavImgWrap>
            </s.MobileHeaderWrap>

            <s.PrimaryNav>
              {
                headerCopy.navItems.map((navItem, i) => (
                  <s.NavItem
                    key={i}
                    border='none'
                    onClick={onOverlayClick}
                    className='mobile-menu'
                    data-design-category="top_nav_vd"
                    data-nav-item={navItem.url}
                  >
                    { navItem.label }
                  </s.NavItem>
                ))
              }
            </s.PrimaryNav>
            {/* <s.MobileDocQuestions>
              <s.MobileDocQuestionTitle>{talkToDoctorCopy.title}</s.MobileDocQuestionTitle>
              <s.MobileDocQuestionCopy>{talkToDoctorCopy.copy}</s.MobileDocQuestionCopy>
              <s.DocQuestionsButton icon='arrow-right' type='primary-inverse' border='white' to={talkToDoctorCopy.buttonTo} onClick={onOverlayClick}>{talkToDoctorCopy.buttonLabel}</s.DocQuestionsButton>
              
            </s.MobileDocQuestions>
            <s.HcpButton className='mobile-menu' border='white' to={headerCopy.hcpButtonTo} data-design-category="top_nav_vd">{headerCopy.hcpButtonLabel}</s.HcpButton>
            <s.MobileFooterLinks>
              {
                  headerCopy.mobileFooterLinks.map((link, i) => (
                    <Button
                      key={i}
                      to={ link.url }
                      underline
                      data-design-category="top_nav_vd"
                    >
                      { link.label }
                    </Button>
                  ))
                }
            </s.MobileFooterLinks> */}
          </s.NavTray>


        

      </s.HeaderWrapper>
    </s.WeliregHeader>
  )
}


export default Header;
