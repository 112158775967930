// Colors ----
export const dotsBlue       =    '#002B49';
export const dotsLightBlue  =    '#C4EDE4';
export const dotsPeach      =    '#FFBE9F';
export const linkBlue       =    '#1E71BA';
export const altBlue        =    '#5450E3';
export const pink           =    '#FFB6C7';
export const dotsPurple     =    '#D9B7E2';
export const limeGreen      =    '#C3E2AD';
export const altLimeGreen   =    '#D9F4F1';
export const leafGreen      =    '#00A537';
export const altLeafGreen   =    '#00892E';
export const teal           =    '#3CBEB2';
export const textBlack      =    '#000000';
export const grayBg         =    '#4C4C4C';
export const altPink        =    `#EE448F`;
export const altTextBlack   =    `#585858`;
export const charcoal       =    `#000000b3`;
export const cream          =    `#FFF6DF`;


// Shades ----
export const white =          '#fff';
export const grey =           '#F6F6F6';
export const greyLightest =   '#f9f9f9';
export const greyLighter =    '#dddddd';
export const greyDark =       '#454545';
export const greyCopy =       '#444444';
export const greyBorder =     '#EEEEEE';    


// Uses ----
export const typeColor      =    greyDark;
export const menuBorder     =    teal;
