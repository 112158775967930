import styled from 'styled-components';


export const StyledLayout = styled.div`
  width: 100%;
  min-height: 100vh;
    // overflow: hidden;
  max-width: 100vw;
`;

export const PageMain = styled.main`
`;
