import styled from "styled-components";
import { respondTo } from '../../../styles/shared/breakpoints';
import { charcoal, textBlack, altLimeGreen } from '../../../styles/shared/colors';

export const Root = styled.div`
    background-color: ${altLimeGreen};
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 0px 0 15px rgba(0,0,0,0.5);
    display: flex; 
    flex-direction: column;
    gap: 17px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    padding: 17px;
    transition: all .3s;

  ${respondTo.sm`
    padding: 25px;
    gap: 25px;
  `}
    .show-desktop {
        display: none;
        ${respondTo.sm`
            display: block;
        `}
    }

    .text-overflow {
        word-break: break-word;
    }

    .header {
        color: ${charcoal};
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;

        ${respondTo.sm`
            font-size: 28px;
            line-height: 35px;
        `}
    }

    .sub-header {
        color: ${textBlack};
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        ${respondTo.sm`
            font-size: 20px;
            line-height: 26px;
        `}

        sup {
            color: 3CBEB2;
            font-size: 7px;

            ${respondTo.sm`
            font-size: 10px;
        `}
        }
    }

    .references {
        color: ${textBlack};
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }

    .arrow-container {
        display: block;
        text-align: center;
    }
`;
