import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { WrapperEl } from '../../../styles/shared/layout';
import { dotsBlue, menuBorder, white, cream, textBlack, grey, greyLighter, greyDark, teal } from '../../../styles/shared/colors';
import iconMenu from '../../../assets/icons/icon-menu.svg';
import iconClose from '../../../assets/icons/icon-menu-close.svg';
import Button from '../Button';
import { respondTo } from '../../../styles/shared/breakpoints';
import { mobileMenu, mobileNavOverlay } from '../../../styles/shared/zindex';


export const WeliregHeader = styled.header`
  position: sticky;
  width: 100%;
  top: 0;
  /* padding: 20px 0; */
  background-color: white;
  z-index: 9;
`;

export const HeaderWrapper = styled(WrapperEl)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1070px;
  /* padding-bottom: 10px; */
`;

export const LogoLink = styled(Link)`
  img {
    display: block;
    height: 47px;
    width: auto;
    transition: 0.3s 0.3s opacity;
  }
  
  @media (min-width: 1024px) {
    height: 60px;

    img {
      height: 60px;
    }
  }
`;

export const NavItems = styled.nav`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    flex: 1 0 0;
    justify-content: space-between;
  }
`;

export const NavItem = styled(Button)`
  line-height: 18px;
  padding: 0 0 10px 0;
  position: relative;
  color: ${textBlack};
  font-weight: normal;
  background-color: transparent;

  @media (min-width: 1024px) and (max-width: 900px){
    flex: 1 0 0;
    line-height: 20px;
    margin: 0px 10px 0 10px;
  }

  &:last-child {
    margin: 0;
  }

  &:not(.mobile-menu) {
    border-bottom: 3px solid ${dotsBlue};
  }

  &.mobile-menu {
    display: block;
    width: 100%;
    padding: 25px 33px 25px 12%;
    text-align: left;
    border-top: 2px solid ${menuBorder};
    font-size: 19px;
    font-weight: 800;
  }
  &:hover {
    background-color: transparent;
  }
  &:hover,
  &.current {
    &:after {
      content: '';
      width: 100%;
      position: absolute;
      height: 3px;
      left: 0;
      bottom: 0;
    }
  }
`;

export const NavLinks = styled.div`
  flex: 1 0 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
`;

export const HcpButton = styled(Button)`
  font-weight: 800;
  font-size: 14px;
  padding: 12px 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 240px;

  &.mobile-menu {
    display: none;
   
  }

  &:hover {
    background: #ffffff;
    color: ${dotsBlue};
  }

`;

export const HamburgerButton = styled.button`
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 1em;
  background-color: transparent;
  background-image: url(${iconMenu});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: block;

  &.open {
    background-image: url(${iconClose});
  }

  @media (min-width: 1024px) {
    display: none;
  }
`
export const MobileNavOverlay = styled.div`
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // height: 100vh;
  // background-color: rgba(50,50,50, 0.7);
  // display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  // z-index: ${mobileNavOverlay};

  // ${respondTo.nav`
  //   display: none;
  // `}
`;

export const NavTray = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  overflow: auto;
  max-height: calc(100vh);
  background-color: ${white};
  box-shadow: 0 0 5px 4px #999;
  transition: all 0.3s ease-in-out; // TODO: Better curve
  z-index: ${mobileMenu};

  &.open {
    width: 100%;
    transform: translateX(-100%);
  }

  &.hidden {
    display: none;
  }

  @media (min-width: 1024px) {
    flex: 1 0 0;
    z-index: auto;
    left: 0;
    width: 0;
    max-height: none;
    padding: 0;
    box-shadow: none;
    transition: none;

    &.open {
      transform: 0;
    }
  }
`;

export const PrimaryNav = styled.nav`
  border-width: 2px 0;
  @media (min-width: 1024px) {
    display: flex;
    height: 100%;
    align-items: stretch;
    padding: 0;
    border: 0;
  }
`;

export const MobileHeaderWrap = styled.nav`
  z-index: 9;
  text-align: right;
`;

export const MobNavImgWrap = styled.div`
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -30px;
  img { max-width: 100%; }
`;

export const SecondaryHeader = styled.div`
  line-height: 30px;
  height: 30px;
  text-align: right;
  background-color: white;
  border-bottom: 1px solid #585858;
  padding: 0;
  font-family: "arial", Helvetica, sans-serif;
  font-size: .64286rem;
  text-align: center;

  @media (min-width: 1024px) {
      padding: 0 2rem;
      font-size: .75rem;
      text-align: right;
  }
`;

export const LeftHeader = styled.div`
  height: 100%;
  background-color: white;
  position: relative;
  z-index: 9;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 600px;
    position: absolute;
    top: -51px;
    width: 220px;
    border-right: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to bottom, black, transparent);
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  a {
    padding: 25px 0 25px 15px;
    position: relative;
    transform: translateY(0);
    font-family: "arial-bold", "arial", Helvetica, sans-serif;
    font-weight: 600;
    color: #000;
    font-size: .9375rem;
    line-height: 1.3125rem;
  }
`;

export const LeftHeaderNavLink = styled.li`
  height: 92px;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${white};
  cursor: pointer;

  a {
    color: ${({active}) => active ? textBlack : greyDark};
    
  }

  &:before {
    display: ${({active}) => active ? 'block' : 'none'};
    content: '';
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -5px;
    transition: left .3s;
    background: #3CBEB2;
    
  }
`;


export const TopHeader = styled.div`
  //overflow: hidden;
  width: 100%;
  transition: all .4s;
  background-color: white;
  position: relative;
  box-shadow: ${({ addShadow }) => (addShadow ? '0px 0 15px rgba(0,0,0,0.5)' : "none")};
  transition: box-shadow .3s ease-in;

  @media (min-width: 1024px) {
    margin: 0 0 0 auto;
    width: calc(100% - 220px);
  }
  
  .text {
    height: 50px;
    background: #3CBEB2;
    border-bottom:1px solid #585858;
    position: relative;
    text-align: center;
    z-index: 1;
    font-size: 14;
    line-height: 18px;
    font-family: 'PT Serif', Georgia, 'Times New Roman', Times, serif;
    color: white;
    font-style: italic;
    font-weight: bold;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 18px;

    @media (min-width: 1024px) {
      font-size: 1.25rem;
      line-height: 1rem;
    }

    sup {
      font-style: normal;
      font-size: .75rem;
    }
  }
`;

export const InfoI = styled.span`
  background: ${({ flipColors }) => (flipColors ? '#3cbeb2' : "#3cbeb2")};

  border-radius: 50%;
  color: ${({ flipColors }) => (flipColors ? `${teal}`: "white")};
  background-color: ${({ flipColors }) => (flipColors ? 'white' : `${teal}`)}; 
  width: 24px;
  transform: translate(10px, 1px);
  line-height: 16px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 1.5px;
  padding-bottom: 2px;
  transition: all .3s;
  margin-right: 25px;
  border: ${({ flipColors }) => (flipColors ? '2px solid white' : "2px solid white")};


  @media (min-width: 1024px) {
    margin-right: 0;
  }
`

export const MobileWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1024px) {
    display: none;
  }
`;
